import axios from "axios";
import { useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

// export const LINK = "https://test-api.finsmart.ru/";
export const LINK = "https://api.finsmart.ru/";

export const useRequest = () => {
  const [ response, setResponse ] = useState<any>( null );
  const [ error, setError ] = useState<any>( null );
  const { executeRecaptcha } = useGoogleReCaptcha();

  const applyRequest = async (
    fields: object,
    type:
      | "main"
      | "program"
      | "calculator"
      | "partners"
      | "quality-service"
      | "media"
      | "maintenance",
    file?: any
  ) => {
    setError( null );

    setResponse( null );

    // @ts-ignore
    fields.token = await executeRecaptcha();
    // @ts-ignore
    console.log( fields.token )

    const form = new FormData();

    form.append( "message", JSON.stringify( fields ) );

    form.append( "type", type );

    if ( file ) {
      form.append( "file", file );
    }

    axios
      .post( LINK + "email", form )
      .then( function ( response ) {
        console.log( "response", response );
        setResponse( response );
      } )
      .catch( function ( error ) {
        console.log( "request error", error );
        setError( error );
      } );
  };

  return { applyRequest, response, error };
};

export const useGetRequest = () => {
  const [ error, setError ] = useState<any>( null );
  const [ GetRequestData, setData ] = useState<any>( null );
  const [ isLoading, setIsLoading ] = useState<any>( null );

  const getRequest = ( path: string ) => {
    setIsLoading( true );
    axios
      .get( LINK + path )
      .then( function ( response ) {
        console.log( "response", response.data );
        setData( response.data );
      } )
      .catch( function ( error ) {
        console.log( "request error", error );
        setError( error );
      } );
    setIsLoading( false );
  };

  return { GetRequestData, getRequest, error, isLoading };
};

export async function getRequest( path: string ) {
  const response = await axios.get( LINK + path );
  return response.data;
}
