import styles from "./textInput.module.scss";
import React, { CSSProperties } from "react";
import InputMask from "react-input-mask";

export const TextInput = (
  {
    className,
    children,
    placeholder,
    dark = false,
    value,
    onChange,
    error = "",
    errorClassName,
    type = "any",
    style,
    mask = "",
    maskChar = "_"
  }: {
    className?: string;
    children?: any;
    placeholder?: string;
    dark?: boolean;
    value?: string;
    onChange: ( it: string ) => void;
    error?: string;
    errorClassName?: string;
    type?: "any" | "decimals" | "letters" | "name" | "city" | "phone" | "company";
    style?: CSSProperties;
    mask?: string | Array<( string | RegExp )>;
    maskChar?: string | null;
  } ) => {
  const capitalizeFirstLetter = ( str: string ) => {
    return str.charAt( 0 ).toUpperCase() + str.slice( 1 );
  };

  const onInputValueChange = ( inputValue: string ) => {
    let filteredValue = inputValue;

    if ( inputValue.length === 1 && ( inputValue === "-" || inputValue === " " ) ) {
      filteredValue = "";
    } else if ( type === "decimals" ) {
      filteredValue = inputValue.replace( /[^\d-]/, "" );
    } else if ( type === "letters" ) {
      filteredValue = inputValue.replace( /[^a-zA-Zа-яА-Я]/, "" );
    } else if ( type === "name" ) {
      filteredValue = inputValue.replace( /[^a-zA-Zа-яА-Я\s-]/g, "" );
    } else if ( type === "city" ) {
      filteredValue = inputValue.replace( /[^a-zA-Zа-яА-Я\s\d-]/g, "" );
    } else if ( type === "phone" ) {
      filteredValue = inputValue.replace( /[^\d-+()]/, "" );
    }

    filteredValue = capitalizeFirstLetter( filteredValue );
    onChange( filteredValue );
  };

  return (
    <div>
      <InputMask
        mask={ mask }
        maskChar={ maskChar }
        // alwaysShowMask={ true }
        className={ [
          styles.container,
          dark ? styles.container__dark : styles.container__light,
          className,
          ( error && ( errorClassName || styles.container__error ) ) || "",
        ].join( " " ) }
        children={ children }
        placeholder={ placeholder }
        value={ value }
        onChange={ ( it ) => onInputValueChange( it.target.value ) }
        style={ style }
      />
      { error &&
          <span className={ errorClassName || styles.container__error }
                style={ { fontSize: 12, opacity: 0.6 } }>{ error }</span>
      }
    </div>
  );
};
