import styles from "./index.module.scss";
import { CommonBlock } from "../../../../components/commonBlock/commonBlock";
import { DesktopBlockPropsType } from "../../../../utils/desktopBlockPropsType";
import { TextInput } from "../../../../components/textInput/textInput";
import { Button } from "../../../../components/button/button";
import { AnimatedBlock } from "../../../../components/animatedBlock/AnimatedBlock";
import { useLocation, useNavigate } from "react-router-dom";
import { BASE_APPLICATION_SCHEMA, CITIES, useForm, } from "../../../../utils/applications";
import { useRequest } from "../../../../utils/api";
import { useEffect, useState } from "react";
import { CityInput } from "../../../../components/cityInput/cityInput";

interface MainPageFormBlockType {
  title: string;
  warning: string;
}

export const MainPageFormBlock = (
  {
    mobile,
    titleShown,
    blocksShown,
    content,
  }: DesktopBlockPropsType & { content: MainPageFormBlockType } ) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [ disableButton, setDisableButton ] = useState<boolean>( false );

  const [ buttonTitle, setButtonTitle ] = useState<string>( "Отправить" );

  const { data, errors, setters, isValidationPassed } = useForm(
    BASE_APPLICATION_SCHEMA
  );

  const inputError = Object.keys( errors ).length > 0;

  const { applyRequest, response, error } = useRequest();

  useEffect( () => {
    if ( response?.status === 200 ) {
      setButtonTitle( "Отправлено!" );
      setDisableButton( true );
    } else {
      setButtonTitle( "Отправить" );
      setDisableButton( false );
    }
  }, [ response, error ] );

  const onApply = async () => {
    if ( isValidationPassed() ) {
      setDisableButton( true );
      setButtonTitle( "Отправка..." );
      applyRequest(
        {
          name: data.name,
          phone: data.phone,
          inn: data.company,
          city: data.city,
          email: data.email,
        },
        "main"
      );
    }
  };
  return (
    <CommonBlock>
      <div className={ styles.container }>
        <AnimatedBlock isShown={ mobile || titleShown } className={ styles.title }>
          { content.title }
        </AnimatedBlock>
        <AnimatedBlock
          isShown={ mobile || blocksShown }
          className={ styles.inputContainer }
        >
          <TextInput
            value={ data.name }
            onChange={ setters.name }
            error={ errors.name }
            className={ styles.input }
            placeholder={ "Имя*" }
            type={ "name" }
          />
          <TextInput
            value={ data.phone }
            onChange={ setters.phone }
            error={ errors.phone }
            className={ styles.input }
            placeholder={ "Телефон*" }
            mask="+7 (999) 999-99-99"
            type={ "phone" }
          />

          <CityInput
            value={ data.city }
            setValue={ setters.city }
            error={ errors.city }
            title={ "Город*" }
            options={ CITIES }
            className={ styles.input }
            style={ "inputFieldForm" }
          />
          <TextInput
            value={ data.email }
            onChange={ setters.email }
            error={ errors.email }
            className={ styles.input }
            placeholder={ "Электронная почта*" }
          />
          <TextInput
            value={ data.company }
            onChange={ setters.company }
            className={ styles.input }
            placeholder={ "ИНН" }
            type={ "decimals" }
            error={ errors.company }
            maskChar=""
            mask={ "999999999999" }
          />
        </AnimatedBlock>
        <div
          className={ [ styles.warning, ( inputError && styles.error ) || "" ].join(
            " "
          ) }
        >
          * — обязательное поле для заполнения
        </div>
        <div
          onClick={ () =>
            navigate( "/privacy-policy", {
              state: { previousLocation: location },
            } )
          }
          className={ styles.warning }
        >
          { content.warning }
        </div>

        <Button
          className={ styles.button }
          title={ buttonTitle }
          onClick={ onApply }
          disabled={ disableButton }
        />
      </div>
    </CommonBlock>
  );
};
